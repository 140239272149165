import ky from "ky"
import {
  ButtonContrastCheckItem,
  ReturnValue,
  Args,
} from "../../../backend/functions/list-buttons-contrast-checks/interfaces"

export async function listButtonsContrastChecks(
  latest: boolean = true
): Promise<ButtonContrastCheckItem[]> {
  const API_ENDPOINT = `${
    process.env.GATSBY_API_URL
  }/list-buttons-contrast-checks${latest ? "?latest" : ""}`
  const result = (await ky
    .get(API_ENDPOINT, { timeout: false })
    .json()) as ReturnValue

  return result.items
}
