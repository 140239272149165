import * as React from "react"

import {
  Box,
  LinkGatsby,
  Heading,
  TextBold,
  List,
  ListItem,
  Text,
  Title,
} from "../../../elements"

import { listButtonsContrastChecks } from "../../../utils/api/list-buttons-contract-checks"
import { ButtonContrastCheckItem } from "../../../../backend/functions/list-buttons-contrast-checks/interfaces"

function useFetcher<T>(fetcher: () => Promise<T>) {
  const [response, setResponse] = React.useState<T | null>(null)
  const [error, setError] = React.useState(null)
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetcher()
        setResponse(res)
      } catch (error) {
        setError(error)
      }
    }
    fetchData()
  }, [])
  return { response, error }
}

export const ListButtonsContrastCheckers: React.FC<{ latest: boolean }> = ({
  latest = true,
}) => {
  const { response: items, error } = useFetcher<ButtonContrastCheckItem[]>(() =>
    listButtonsContrastChecks(latest)
  )

  if (error) {
    return <pre>Something went wrong...</pre>
  }

  if (!items) {
    return <pre>Loading...</pre>
  }

  return (
    <Box>
      <List display="flex" flexWrap="wrap" m={-5}>
        {items.map(item => (
          <ListItem key={item.slug} p={5} width={[1, 1, 1 / 2, 1 / 3]}>
            <article>
              <TextBold as="h2">
                <LinkGatsby
                  to={`/button-contrast-checker/${item.slug}`}
                  css={{
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.slug}
                </LinkGatsby>
              </TextBold>
              <Box mt={1} />
              <Text>
                {new Date(item.auditedAt).toLocaleString("en", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </Text>
            </article>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
