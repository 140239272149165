import * as React from "react"
import { Box, Container, Title, LinkGatsby } from "../../elements"

import { ListButtonsContrastCheckers } from "../../partials/admin/ListButtonsContrastCheckers"
import { SEO } from "../../components"

export default function LatestButtonsContrastCheckers() {
  return (
    <>
      {/* <SEO
        title={"Aditus Admin"}
        description={"Aditus Description"}
        keywords=""
      >
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </SEO> */}
      <Box py={10}>
        <Container maxWidth="l">
          <Title>Latest reports</Title>
          <Box mt={2} />
          <LinkGatsby to="/admin/all">All Reports</LinkGatsby>
          <Box mt={4} />
          <ListButtonsContrastCheckers latest={true} />
        </Container>
      </Box>
    </>
  )
}
